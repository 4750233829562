<template>
  <div class="matchmakerTeamIntro">
    <v-h5Mtml :content="postData.content"></v-h5Mtml>
  </div>
</template>

<script>
import { getDsfContentInfoListUrl } from "./api";
export default {
  name: "matchmakerTeamIntro",
  data() {
    return {
      postData: {
        content: "",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  async mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let params = {
        curPage: 1,
        pageSize: 99,
        status: 1,
      };
      let res = await this.$axios.get(`${getDsfContentInfoListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        let oList = res.data && res.data.records;
        if (oList && oList.length != 0) {
          oList.forEach((element) => {
            if (element.type == "团队介绍") {
              this.postData = element;
            }
          });
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmakerIntro {
  min-height: 100vh;
  img {
    width: 100%;
  }
}
</style>
